// https://www.npmjs.com/package/slick-carousel
import $ from 'jquery';

$(($) => {

	if ($('.js-GenericSlideshow > div').length > 1) {
		$('.js-GenericSlideshow').slick({
			arrows: false,
			dots: true,
			fade: true,
			autoplay: true,
		});
	}

});